<template>
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <!-- Inicio sla primer contacto acciones cita -->
                    <div class="col-md-2">
                        <div class="card card-info card-outline">
                            <div class="card-body ">
                                <h6>Primer contacto</h6>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.limite') }}</span>
                                    </div>
                                    <input type="number" v-model="primer_contacto" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(primer_contacto)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                    </div>
                                    <input type="number" v-model="primer_contacto_aviso" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(primer_contacto_aviso)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin sla primer contacto acciones cita -->
                    <!-- Inicio sla concertar cita -->
                    <div class="col-md-2">

                        <div class="card card-danger card-outline">
                            <div class="card-body ">
                                <h6>{{ $t('general.concertadaprimeracita') }}</h6>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.limite') }}</span>
                                    </div>
                                    <input type="number" v-model="primera_cita" class="form-control" placeholder="hh">
                                    <input type="number" :value="sacarDias(primera_cita)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                    </div>
                                    <input type="number" v-model="primera_cita_aviso" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(primera_cita_aviso)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin sla concertar cita -->
                    <!-- Inicio sla máxima 1ª cita -->
                    <div class="col-md-2">

                        <div class="card card-warning card-outline">
                            <div class="card-body ">
                                <h6>{{ $t('general.fechamax') }}</h6>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.limite') }}</span>
                                    </div>
                                    <input type="number" v-model="fecha_max_primera_cita" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(fecha_max_primera_cita)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                    </div>
                                    <input type="number" v-model="fecha_max_primera_cita_aviso" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(fecha_max_primera_cita_aviso)"
                                        class="form-control" placeholder="dd" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin sla máxima 1ª cita -->
                    <!-- Inicio sla Finalización -->
                    <div class="col-md-2">

                        <div class="card card-olive card-outline">
                            <div class="card-body ">
                                <h6>{{ $t('general.finalizacion') }}</h6>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.limite') }}</span>
                                    </div>
                                    <input type="number" v-model="finalizacion" class="form-control" placeholder="hh">
                                    <input type="number" :value="sacarDias(finalizacion)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                    </div>
                                    <input type="number" v-model="finalizacion_aviso" class="form-control"
                                        placeholder="hh">
                                    <input type="number" :value="sacarDias(finalizacion_aviso)" class="form-control"
                                        placeholder="dd" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin sla máxima 1ª cita -->
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>{{ $t('general.tiposdedano') }}</label>

                            <MultiSelect optionValue="id" scrollHeight="400px" showClear
                                v-model="tiposdanosseleccionado" :options="tiposdano" filter optionLabel="danyo"
                                style="width:100%" placeholder="Tipos daño">
                            </MultiSelect>

                            <!-- <select class="select2" v-model="tiposdanosseleccionado" multiple data-placeholder="Tipo de daño" style="width: 100%;">
                                <option v-for="tipodano in tiposdano" :value="tipodano.id"  :key="tipodano">{{ tipodano.danyo }}</option>
                                
                            </select>-->
                        </div>
                    </div>
                    <div class="col-md-2" v-if="datos.sistema_compannia == 'P.A.P.'">
                        <div class="form-group">
                            <label>Tratamientos</label>
                            <MultiSelect scrollHeight="400px" showClear v-model="tratamientoseleccionado"
                                :options="Object.keys(tratamientos)" filter style="width:100%"
                                placeholder="Tratamiento">
                                <template #option="slotProps">
                                    {{ tratamientos[slotProps.option] }}
                                </template><!--
                                <template #chip="slotProps">
                                    {{ tratamientos[slotProps.value] }}
                                </template>-->
                            </MultiSelect>
                            <!-- <select class="form-control" v-model="tratamientoseleccionado"
                                data-placeholder="Tratamiento" style="width: 100%;">
                                <option v-for="tratamiento in Object.keys(tratamientos)" :value="tratamiento"
                                    :key="tratamiento">{{tratamientos[tratamiento]}}</option>
                            </select>-->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <button v-if="nuevo==true" class="btn btn-sm btn-success" @click="crearSLA()">Crear
                            nuevo</button>
                        <button v-if="nuevo==false" class="btn btn-sm btn-success" @click="crearSLA()">Guardar</button>

                    </div>
                    <div class="col-md-1">
                        <button v-if="nuevo == false" class="btn btn-sm btn-danger" @click="eliminarSLA()">{{
                            $t('general.eliminar') }}</button>

                    </div>
                </div>
            </div>
        </div>
        <!--<div class="card-footer">

        </div>-->
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import MultiSelect from 'primevue/multiselect';
export default {
    components: {
        MultiSelect
    },
    props: ['id', 'datos','tiposdano','tratamientos','nuevo','sla'],
    data() {
        return {
            tratamientoseleccionado: [],
            tiposdanosseleccionado: [],
            primer_contacto: '',
            primer_contacto_aviso: '',
            primera_cita: '',
            primera_cita_aviso: '',
            fecha_max_primera_cita: '',
            fecha_max_primera_cita_aviso: '',
            finalizacion: '',
            finalizacion_aviso: ''
        }
    },

    methods: {
        cargardatos() {
            this.tiposdanosseleccionado = [];
            this.tratamientoseleccionado = [];
            if (this.$props.nuevo == false) {
                
                this.primer_contacto = this.$props.sla.primer_contacto;
                this.primer_contacto_aviso = this.$props.sla.primer_contacto_aviso;
                this.primera_cita = this.$props.sla.primera_cita;
                this.primera_cita_aviso = this.$props.sla.primera_cita_aviso;
                this.fecha_max_primera_cita = this.$props.sla.fecha_max_primera_cita;
                this.fecha_max_primera_cita_aviso = this.$props.sla.fecha_max_primera_cita_aviso;
                this.finalizacion = this.$props.sla.finalizacion;
                this.finalizacion_aviso = this.$props.sla.finalizacion_aviso;
                console.log("tiposdanosaux", this.$props.sla.tipos_danyos.split(','));
                this.tiposdanosseleccionado = this.$props.sla.tipos_danyos.split(',');
                if (this.$props.sla.tratamientos && this.$props.sla.tratamientos != ","){
                    this.tratamientoseleccionado = this.$props.sla.tratamientos.split(',');
                }
                this.tiposdanosseleccionado = this.tiposdanosseleccionado.filter((item, index) => {
                    return this.tiposdanosseleccionado.indexOf(item) === index;
                })
            }



        },
       async eliminarSLA() {
            //DELETE companias/:id/slas/eliminar
            const api = new PwgsApi();

           try {
               await api.deletebody('companias/' + this.$props.id + '/slas/eliminar', { tipos_danos: this.$props.sla.tipos_danyos.split(',') });
               this.$toast.add({ severity: 'success', summary: 'Eliminado', detail: 'Eliminado correctamente', life: 5000 });
               this.$parent.obtenerSLAs();
           }
           catch (error) {
               this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
           }  

        },
         async crearSLA() {
            //POST companias/:id/slas
            console.log("tipodanyoselecc", this.tiposdanosseleccionado);
           const api = new PwgsApi();
             console.log("tratamientoseleccionado", this.tratamientoseleccionado);
             let subidadatos = {};
             if (this.tratamientoseleccionado) {
                 subidadatos = {
                     tratamiento: this.tratamientoseleccionado,
                     tipos_danos: this.tiposdanosseleccionado, datos: {
                         primer_contacto: this.primer_contacto,
                         primer_contacto_aviso: this.primer_contacto_aviso,
                         primera_cita: this.primera_cita,
                         primera_cita_aviso: this.primera_cita_aviso,
                         fecha_max_primera_cita: this.fecha_max_primera_cita,
                         fecha_max_primera_cita_aviso: this.fecha_max_primera_cita_aviso,
                         finalizacion: this.finalizacion,
                         finalizacion_aviso: this.finalizacion_aviso
                     }
                 }
             }
             else {
                 subidadatos = {
                     tipos_danos: this.tiposdanosseleccionado, datos: {
                         primer_contacto: this.primer_contacto,
                         primer_contacto_aviso: this.primer_contacto_aviso,
                         primera_cita: this.primera_cita,
                         primera_cita_aviso: this.primera_cita_aviso,
                         fecha_max_primera_cita: this.fecha_max_primera_cita,
                         fecha_max_primera_cita_aviso: this.fecha_max_primera_cita_aviso,
                         finalizacion: this.finalizacion,
                         finalizacion_aviso: this.finalizacion_aviso
                     }
                 }
             }
            try {
                await api.post('companias/' + this.$props.id + '/slas', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Guardado correctamente', life: 5000 });
                this.$parent.obtenerSLAs();
                this.vaciarInputs();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }  
        },
        sacarDias(valor) {
            let aux = 0;
            aux = parseFloat(valor);
           return (Math.round((aux / 24) * 100) / 100).toFixed(2);

        },
        vaciarInputs() {
            if (this.$props.nuevo == true) {

                this.primer_contacto = '';
                this.primer_contacto_aviso = '';
                this.primera_cita = '';
                this.primera_cita_aviso = '';
                this.fecha_max_primera_cita = '';
                this.fecha_max_primera_cita_aviso = '';
                this.finalizacion = '';
                this.finalizacion_aviso = '';
                this.tiposdanosseleccionado = [];
                this.tratamientoseleccionado = [];
            }
        }
    },
    watch: {
        id() {
        },
        sla() {
            
        },
        tratamientos() {

            
        }

    },
    mounted() {
        this.cargardatos();
    },
    computed: {
       
    },

}

</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>